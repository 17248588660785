@import '../../Utils/Styles/Colors.css';
@import '../../Utils/Styles/Fonts.css';

.Mission_Screen_Container {
    max-width: 1170px;
    margin: 0 auto;
}

.inner_mission_container {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}


.inner_mission_container .mission_header_Title {
    margin: 20px 0 50px 0;
}

.inner_mission_container .mission_header_Title h1 {
    font-family: Nunito;
    font-size: 15px;
    font-style: normal;
    font-weight: 700;
    color: var(--primary-button-color);
    text-align: center;
}


.mission_text_below_header {
    width: 1017px;
}

.mission_text_below_header h1 {
    text-align: center;
    font-family: Nunito;
    font-size: 32px;
    font-style: normal;
    font-weight: 700;
    color: var(--primary-text-color);
    line-height: 130%;
}

.mission_banner_container {
    width: 701px;
    height: 289px;
    margin-top: 71px;
}

.mission_banner_container img {
    max-width: 100%;
    height: auto;
    display: block;
    margin: 0 auto;
}

.mission_text_below_header_mobile {
    display: none;
}


@media only screen and (min-width: 768px) and (max-width: 1024px) {
    .Mission_Screen_Container {
        margin-top: 35px;
    }

    .mission_text_below_header {
        display: none;
    }

    .inner_mission_container .mission_header_Title {
        margin: 26px 0;
    }

    .mission_text_below_header_mobile {
        display: block;
        width: 100% !important;
        margin-bottom: 30px;
    }

    .mission_text_below_header_mobile h1 {
        color: var(--primary-text-color);
        text-align: center;
        font-family: Nunito;
        font-size: 28px;
        font-style: normal;
        font-weight: 700;
        line-height: 130%;
    }

    .mission_banner_container {
        display: none;
    }

    .Common_btn_container {
        margin: 0 !important;
    }

}

@media (max-width: 768px) {

    .Mission_Screen_Container {
        margin-top: 35px;
    }

    .mission_text_below_header {
        display: none;
    }

    .inner_mission_container .mission_header_Title {
        margin: 26px 0;
    }

    .mission_text_below_header_mobile {
        display: block;
        width: 334px;
        margin-bottom: 30px;
    }

    .mission_text_below_header_mobile h1 {
        color: var(--primary-text-color);
        text-align: center;
        font-family: Nunito;
        font-size: 28px;
        font-style: normal;
        font-weight: 700;
        line-height: 130%;
    }

    .mission_banner_container {
        display: none;
    }

    .Common_btn_container {
        margin: 0 !important;
    }
}