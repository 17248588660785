@import '../../Utils/Styles/Colors.css';
@import '../../Utils/Styles/Fonts.css';

.service-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.service-header-title-container h1 {
    color: var(--primary-text-color);
    font-family: Nunito;
    font-size: 25px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}

.no-pending-request {
    margin-top: 25px;
}

.no-pending-request p {
    color: var(--primary-text-color);
    font-family: Nunito;
    font-size: 17px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}

.PendingServiceRequest_table_container {
    width: 100%;
    border-collapse: collapse;
    margin: 20px 0;
    text-align: center;
    color: #29282F;
    font-family: Nunito;
    font-size: 15px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}

.PendingServiceRequest_table_container thead tr th {
    padding: 10px;
    color: var(--dashboard-border-color);
    font-family: Nunito;
    font-size: 13px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;

}

.PendingServiceRequest_table_container thead tr th:nth-child(1) {
    width: 15%;
}

.PendingServiceRequest_table_container thead tr th:nth-child(2) {
    width: 15%;
}

.PendingServiceRequest_table_container thead tr th:nth-child(3) {
    width: 30%;
}

.action-buttons {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10px;
}

.accept-button,
.reject-button {
    padding: 8px 16px;
    cursor: pointer;
    border: none;
    border-radius: 4px;
    color: #fff;
}

.accept-button {
    background-color: #28a745;
}

.reject-button {
    background-color: #dc3545;
}