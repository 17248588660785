@import '../../Utils/Styles/Colors.css';
@import '../../Utils/Styles/Fonts.css';

.loader-container {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    background: rgba(0, 0, 0, 0.5);
    z-index: 999;
}

.wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    background-color: var(--default-background);
    width: 30%;
    padding: 24px 0 24px 0;
    border-radius: 8px;

}

.loader-spinner {
    border: 4px solid rgba(0, 0, 0, 0.1);
    border-top: 4px solid var(--primary-button-color);
    border-radius: 50%;
    width: 40px;
    height: 40px;
    animation: spin 1s linear infinite;

}

.LoadingText {
    color: var(--primary-button-color);
    font-family: Nunito;
    font-size: 35px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    margin-top: 15px;
}

@keyframes spin {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}

/* Tablet styles (adjust as needed) */
@media only screen and (max-width: 768px) {
    .wrapper {
        width: 60%;
    }
}

/* Mobile styles (adjust as needed) */
@media only screen and (max-width: 480px) {
    
    .LoadingText {
        font-size: 20px;
        margin-top: 10px;
    }

   
}