@import url("../../Utils/Styles/Colors.css");

@import '../../Utils/Styles/Fonts.css';

header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 16px 16px 10px 16px;
    border-bottom: 2px var(--border-color) solid;
    position: sticky;
    top: 0;
    background-color: var(--secondary-text-color);
}

.logo-container {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    cursor: pointer;
}

.logo-img {
    height: 70px;
    width: auto;
}

.login-register-btn-container {
    display: flex;
}

.logo-text {
    font-family: Nunito;
    font-weight: 700;
    font-style: normal;
    font-size: 35px;
    margin-left: 12px;
}

a {
    text-decoration: none;
    color: var(--primary-text-color);
}


@media screen and (max-width:480px) {
    .logo-img {
        height: 55px;
    }
}