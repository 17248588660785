@import '../../Utils/Styles/Colors.css';
@import '../../Utils/Styles/Fonts.css';


.main-content-header h1 {
    color: var(--primary-text-color);
    font-family: Nunito;
    font-size: 25px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}

.titles_container {
    display: flex;
    align-items: center;
    justify-content: space-around;
    margin: 125px 25px 25px 65px;
}

.titles_container li {
    list-style: none;
}

.accounts_container {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.accounts_container .title_name {
    color: #29282F;
    font-family: Nunito;
    font-size: 15px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}

.accounts_container .Total_Numbers {
    color: var(--primary-text-color);
    font-family: Nunito;
    font-size: 40px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    margin-top: 31px;
}

table {
    width: 100%;
    border-collapse: collapse;
    margin: 20px 0;
    text-align: center;
    color: #29282F;
    font-family: Nunito;
    font-size: 15px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}

th,
td {
    padding: 12px;
    border: 1px solid #ddd;

}

th {
    background-color: #f4f4f4;
    color: #333;
    font-weight: bold;
}

tr:nth-child(even) {
    background-color: #f9f9f9;
}

tr:hover {
    background-color: #f1f1f1;
}

td {
    text-align: center;
    background-color: var(--secondary-text-color);
}

.type-common-class {
    background-color: white;
    border: none;
    font-weight: bold;
}

.button-common-class {
    background-color: white;
    border: none;
    cursor: pointer;
}