@import '../../Utils/Styles/Colors.css';
@import '../../Utils/Styles/Fonts.css';

body,
html {
    height: 100%;
    margin: 0;
}

.modal {
    display: none;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.5);
    z-index: 999;
    /* background: none !important;  */

}

.modal-content .error_message_text {
    color: var(--primary-text-color);
    font-family: Nunito;
    font-size: 25px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}

.modal.open .modal-content {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 30%;
    background-color: var(--default-background);
    border-radius: 8px;
    padding: 20px;
    text-align: center;
    animation: fade-in 0.3s ease-in-out;
}

.Error_button {
    margin-top: 25px;
    padding: 8px 16px;
    background-color: var(--primary-button-color);
    color: var(--default-background);
    border: none;
    border-radius: 4px;
    cursor: pointer;
    font-family: Nunito;
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}

@media screen and (max-width: 768px) {
    .modal.open .modal-content {
        width: 80%;
    }
}

/* Media query for mobile devices */
@media screen and (max-width: 480px) {
    .modal.open .modal-content {
        width: 90%;
        height: 30%;
        padding: 15px;
        padding-top: 25px;
    }
}