@import '../../Utils/Styles/Colors.css';
@import '../../Utils/Styles/Fonts.css';

.footer_Screen_Container {
    max-width: 1260px;
    margin: 0 auto;

}

.upper_container {
    padding: 62px 0 42px 0;
    display: flex;
    justify-content: space-between;
}

.footerlogo {
    margin: 0;
    padding: 0;
    width: 180px;
    height: auto;
}

.upper_container .left_container {
    display: flex;
    justify-content: space-between;
    width: 500px;
}

.platform_icons_container {
    width: 162px;
    height: 127px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.platform_icons_container img {
    max-width: 100%;
    height: auto;
    display: block;

}

.footer_navbar_list {
    width: 610px;
}

.footer_navbar_list .Navbar_List li a {
    color: var(--default-background);
    font-family: Nunito;
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    outline: none;
}

.lower_container {
    display: flex;
    justify-content: space-between;
    margin-top: 24px;
}

.lower_left_container {
    display: flex;
    align-items: center;
    width: 830px;
    margin-bottom: 40px;
}


.lower_container .copyrights_container p {
    color: var(--footer_text_color);
    font-family: Nunito;
    font-size: 13px;
    font-style: normal;
    font-weight: 300;
    line-height: normal;
}

.lower_container .terms_condition_container ul {
    width: 437px;
}

.lower_container .terms_condition_container ul li {
    list-style: none;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
}

.lower_container .terms_condition_container ul li a {
    text-decoration: none;
    color: #657887;
    font-family: Nunito;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    cursor: pointer;
}

.lower_container .social_icons_container ul {
    display: flex;
    justify-content: space-evenly;
    width: 160px;
}

.lower_container .social_icons_container ul li {
    list-style: none;
}

.lower_container .social_icons_container ul li a {
    text-decoration: none;
    width: 20.082px;
    height: 20.082px;
}

.mobile_container {
    display: none;
}

@media (max-width: 768px) {

    .upper_container {
        display: none;
    }

    .lower_container {
        display: none;
    }

    .mobile_container {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-evenly;
        height: 442px;
    }


    .mobile_container .social_icons_container ul {
        display: flex;
        justify-content: space-evenly;
        width: 130px;
    }

    .mobile_container .social_icons_container ul li {
        list-style: none;
    }

    .mobile_container .social_icons_container ul li a {
        text-decoration: none;
        width: 20.082px;
        height: 20.082px;
    }

    .mobile_container .terms_condition_container ul li {
        list-style: none;
        display: flex;
        flex-direction: column;
        justify-content: space-around;
        align-items: center;
        height: 85px;
    }

    .mobile_container .terms_condition_container ul li a {
        text-decoration: none;
        color: #657887;
        font-family: Nunito;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
    }



    .mobile_container .copyrights_container p {
        color: var(--footer_text_color);
        font-family: Nunito;
        font-size: 13px;
        font-style: normal;
        font-weight: 300;
        line-height: normal;
    }

    .footerlogo {
        width: 140px;
    }

}