@import '../../Utils/Styles/Colors.css';
@import '../../Utils/Styles/Fonts.css';

.Hope_App_Services_container {
    max-width: 1170px;
    margin: 0 auto;
}

.Inner_Services_Container {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}

.Inner_Services_Container .header_Title {
    margin: 20px 0;
}

.Inner_Services_Container .header_Title h1 {
    font-family: Nunito;
    font-size: 15px;
    font-style: normal;
    font-weight: 700;
    color: var(--primary-button-color);
    text-align: center;
}

.Inner_Services_Container .text_below_header h1 {
    text-align: center;
    font-family: Nunito;
    font-size: 40px;
    font-style: normal;
    font-weight: 700;
    color: var(--primary-text-color);
}

.custom_Design_container {
    width: 1170px;
    position: relative;
    margin-top: 52px;
    display: flex;
}

.card_one_container {
    margin-right: 25px;
    width: 49%;
}

.card_two_container {
    width: 49%;
    margin-left: 25px;

}

.card_header_container {
    position: absolute;
    top: 0;
    left: 149px;
    background-color: var(--default-background);
    width: 280px;
    height: 61px;
}

.card_two_header_container {
    position: absolute;
    top: 0;
    right: 149px;
    background-color: var(--default-background);
    width: 280px;
    height: 61px;
}

.card_header,
.card_two_header {
    text-align: center;
}

.card_header h1,
.card_two_header h1 {
    color: var(--secondary-button-color);
    font-family: Nunito;
    font-size: 26px;
    font-style: normal;
    font-weight: 700;
    margin: 0;
    padding: 0;
}

.card_sub_header,
.card_two_sub_header {
    text-align: center;
}

.card_sub_header p,
.card_two_sub_header p {
    margin: 0;
    padding: 0;
    color: var(--secondary-button-color);
    font-family: Nunito;
    font-size: 20px;
    font-style: normal;
    font-weight: 700;
}

.card_content_container {
    margin-top: 25px;
    border-radius: 40px;
    border: 2px solid var(--secondary-button-color);
    height: auto;
    padding: 20px;
}

.card_content_container .purple_card_container {
    margin-top: 65px;
    display: flex;
    align-items: center;
    justify-content: space-around;
}

.card_content_container .purple_card_container .purple_cards {
    width: 210px;
    height: 162px;
    border-radius: 24px;
    background: var(--light-blue);
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.patient_icon_container img {
    width: 54px;
    height: 39px;
}


.card_content_container .purple_card_container .purple_cards h1 {
    color: var(--primary-text-color);
    font-family: Nunito;
    font-size: 22px;
    font-style: normal;
    font-weight: 700;
    margin: 0;
    padding: 0;
}


.care_circle_icon_container img {
    width: 58px;
    height: 47px;
}

.recommendation_container_hope {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 24px;
    height: 189px;
}

.recommendation_container_hope ul li,
.recommendation_container_Professionals .care_professionals ul li,
.recommendation_container_Professionals .medical_services ul li {
    margin-top: 10px;
}

.recommendation_container_hope ul li h3,
.recommendation_container_Professionals .care_professionals ul li h3,
.recommendation_container_Professionals .medical_services ul li h3 {
    color: var(--primary-text-color);
    font-family: Nunito;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
}

.Care_Professional_container img {
    width: 99px;
    height: 39px;
}

.medical_services_container img {
    width: 54px;
    height: 50px;
}

.Care_Professionals_text {
    color: var(--primary-text-color);
    text-align: center;
    font-family: Nunito;
    font-size: 22px;
    font-style: normal;
    font-weight: 700;
}

.Medical_Services_text {
    color: var(--primary-text-color);
    text-align: center;
    font-family: Nunito;
    font-size: 22px;
    font-style: normal;
    font-weight: 700;
}

.recommendation_container_Professionals {
    display: flex;
    align-items: center;
    justify-content: space-around;
    margin-top: 24px;

}

.care_professionals {
    margin-left: 50px;
}

.medical_services {
    margin-left: 100px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.card_content_container .platform_icons_container {
    display: flex;
    width: 100%;
    flex-direction: row;
    align-items: center;
    justify-content: space-evenly;
}

.card_content_container .platform_icons_container img {
    width: 175px;
    height: auto;
    display: block;
}

@media only screen and (min-width: 768px) and (max-width: 1024px) {

    .Inner_Services_Container .header_Title {
        margin: 16px auto 32px auto;
    }

    .Inner_Services_Container .header_Title h1 {
        font-size: 15px;
        line-height: 100%;
    }

    .Inner_Services_Container .text_below_header h1 {
        font-size: 28px;
        line-height: 130%;
    }


    .custom_Design_container {
        flex-direction: column;
        width: 100%;
        margin-top: 20px
    }

    .card_one_container {
        margin: 0 auto !important;
        width: 84%;
        position: relative;
    }

    .card_two_container {
        margin: 25px auto !important;
        width: 84%;
        position: relative;
    }

    .recommendation_container_hope ul li {
        margin-top: 20px;
    }

    .card_header_container,
    .card_two_header_container {
        position: absolute;
        top: 0;
        left: 30% !important;
        background-color: var(--default-background);
        width: 255px;
        height: 61px;
    }

    .card_content_container {
        padding: 7px;
        width: 100%;
    }

    .card_content_container .purple_card_container {
        width: 100%;
    }

    .card_content_container .purple_card_container .purple_cards {
        width: 143px;
    }

    .card_two_sub_header p {
        font-size: 19px;
    }

    .card_content_container .purple_card_container .purple_cards .Care_Professionals_text,
    .card_content_container .purple_card_container .purple_cards .Medical_Services_text {
        font-size: 20px;
    }

    .medical_services_container,
    .Care_Professional_container {
        margin-bottom: 5px;
        margin-top: 5px;
    }

    .care_professionals {
        margin-left: 37px;
    }

    .medical_services {
        margin-left: 53px;
    }

    .card_content_container .platform_icons_container {
        flex-direction: column;
        margin: 25px 0;
        justify-content: space-between;
    }

}




@media (max-width: 768px) {


    .Inner_Services_Container .header_Title {
        margin: 16px auto 32px auto;
    }

    .Inner_Services_Container .header_Title h1 {
        font-size: 15px;
        line-height: 100%;
    }

    .Inner_Services_Container .text_below_header h1 {
        font-size: 28px;
        line-height: 130%;
    }


    .custom_Design_container {
        flex-direction: column;
        width: 100%;
        margin-top: 20px
    }

    .card_one_container {
        margin: 0 0 0 24px;
        width: 84%;
        position: relative;
    }

    .card_two_container {
        margin: 25px 0 0 24px;
        width: 84%;
        position: relative;
    }

    .recommendation_container_hope ul li {
        margin-top: 20px;
    }

    .card_header_container,
    .card_two_header_container {
        position: absolute;
        top: 0;
        left: 13%;
        background-color: var(--default-background);
        width: 255px;
        height: 61px;
    }

    .card_content_container {
        padding: 7px;
        width: 100%;
    }

    .card_content_container .purple_card_container {
        width: 100%;
    }

    .card_content_container .purple_card_container .purple_cards {
        width: 143px;
    }

    .card_two_sub_header p {
        font-size: 19px;
    }

    .card_content_container .purple_card_container .purple_cards .Care_Professionals_text,
    .card_content_container .purple_card_container .purple_cards .Medical_Services_text {
        font-size: 20px;
    }

    .medical_services_container,
    .Care_Professional_container {
        margin-bottom: 5px;
        margin-top: 5px;
    }

    .care_professionals {
        margin-left: 37px;
    }

    .medical_services {
        margin-left: 53px;
    }

    .card_content_container .platform_icons_container {
        flex-direction: column;
        margin: 25px 0;
        justify-content: space-between;
    }

}