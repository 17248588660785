@import '../../Utils/Styles/Colors.css';
@import '../../Utils/Styles/Fonts.css';


.Solution_Section_Container {
    margin: 0 auto;
}

.Solution_Section_Container .header_Title {
    margin: 20px 25px 20px 0;
}

.Solution_Section_Container .header_Title h1 {
    color: var(--primary-button-color);
    font-family: Nunito;
    font-size: 15px;
    font-style: normal;
    font-weight: 700;
    text-align: center;
}

.Solution_Section_Container .text_below_header h1 {
    color: var(--primary-text-color);
    font-family: Nunito;
    font-size: 40px;
    font-style: normal;
    font-weight: 700;
    text-align: center;

}

.card_container {
    width: 308px;
    height: 296px;
    border-radius: 22px;
    padding-top: 46px;
    margin: 45px 0;
}

.card_container .card_text_container {
    width: 210px;
    margin: 0 49px;
    height: 124px;
}

.card_container .card_text_container h1 {
    color: var(--primary-text-color);
    font-family: Nunito;
    font-size: 23px;
    font-style: normal;
    font-weight: 600;
    margin: 0;
}

.icon_container {
    margin: 61px 49px 35px 49px;
}

.icon_container img {
    height: auto;
    display: block;
    max-width: 100%;
}

.react-multiple-carousel__arrow {
    background: var(--secondary-button-color) !important;
    min-width: 50px !important;
    min-height: 50px !important;
}

.react-multiple-carousel__arrow--right {
    right: calc(4% + -43px) !important;
    z-index: 0 !important;
}

.react-multiple-carousel__arrow--left {
    z-index: 0 !important;

}

.card-color-1 {
    background-color: #E3E4F0;
}

.card-color-2 {
    background-color: #FFF2B2;
}

.card-color-3 {
    background-color: #E3D8FF;
}

.card-color-4 {
    background-color: #D6D9E0;
}

.card-color-5 {
    background-color: #FFEA80;
}

.card-color-6 {
    background-color: #C8B1FF;
}

.card-color-7 {
    background-color: #D6D9E0;
}

.react-multi-carousel-track {
    margin: 0 85px !important;
}

.react-multiple-carousel__arrow::before {
    color: var(--primary-text-color) !important;
}

.mobile_carousal_container {
    display: none;
}


@media (max-width: 768px) {

    .Solution_Section_Container .header_Title {
        margin: 0 auto 32px auto;
    }

    .Solution_Section_Container .header_Title h1 {
        font-family: Nunito;
        font-size: 15px;
        font-style: normal;
        font-weight: 700;
        line-height: 100%;
    }

    .Solution_Section_Container .text_below_header h1 {
        font-size: 28px;
        line-height: 130%;
    }

    .Solution_Section_Container .web_carousal_container {
        display: none;
    }


    .mobile_carousal_container {
        display: flex;
        overflow: auto;
        padding: 0 25px;
    }

    .mobile_carousal_container::-webkit-scrollbar {
        width: 0.5em;
    }

    .mobile_carousal_container::-webkit-scrollbar-track {
        background-color: transparent;
    }

    .mobile_carousal_container::-webkit-scrollbar-thumb {
        background-color: transparent;
    }

    .card_container {
        width: 245px;
        height: 290px;
        margin: 30px 13px;

    }

    .card_container .card_text_container {
        width: 168px;
        margin: 0 25px;
    }

    .card_container .card_text_container h1 {
        font-size: 20px;
    }

    .icon_container {
        width: 55px;
        height: 50px;
        margin-left: 25px;
    }

    .react-multi-carousel-item {
        margin-right: 75px;
    }


}