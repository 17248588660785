@import '../../Utils/Styles/Colors.css';
@import '../../Utils/Styles/Fonts.css';

.ContactUs_Screen_Container {
    max-width: 1170px;
    margin: 0 auto;
}


.ContactUs_inner_container {
    margin-bottom: 66px;
    padding: 0 52px;
}


.ContactUs_header_Title {
    margin: 20px 0;
}

@import '../../Utils/Styles/Colors.css';
@import '../../Utils/Styles/Fonts.css';


.Sign-container {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 20px;
    align-content: center;
}

.numberError {
    width: 60%;
}

.main-container {
    background: var(--default-background);
    padding: 20px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    height: 100vh;
}

.content-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 100%;
}

.svgLogoImg {
    margin-top: 50px;
}

.headerTextContainer {
    margin-top: 65px;
}

.headerTextContainer h1 {
    color: var(----primary-text-color);
    font-family: Nunito;
    font-size: 30px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
}

.headerTextContainer p {
    color: var(----primary-text-color);
    font-family: Nunito;
    font-size: 15px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    text-align: center;
}

.msgIcon {
    width: 24px;
    height: 24px;
    flex-shrink: 0;
}

.verticle_line {
    width: 1px;
    height: 47px;
    /* margin-left: 5px;
    margin-right: 5px; */
    opacity: 0.6;
    background: var(--Line-color);
}

.phoneNo_container p {
    color: var(--placeholder-text-color);
    font-family: Nunito;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    text-align: start;
}

.phoneNo_container input {
    width: 100%;
    border: none;
    color: var(--primary-text-color);
    font-family: Nunito;
    font-size: 15px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
}

.phoneNo_container input:focus {
    outline: none;
}


.checkCircle {
    width: 24px;
    height: 24px;
    flex-shrink: 0;
    margin-left: 61px;
}

/* .sign-in-button1 {
    width:33%;
    display: flex;
    padding: 19px 150px 19px 80px;
    justify-content: flex-end;
    align-items: center;
    border-radius: 14px;
    background: var(--primary-button-color);
    margin-top: 29px;
    cursor: pointer;
    margin-left: 80px;
    margin-right: 80px;
    color: var(--default-background);
} */

.sign-in-button1 {
    width: 400px;
    background: var(--primary-button-color);
    color: var(--default-background);
    border-radius: 14px;
    text-align: center;
    padding-top: 20px;
    padding-bottom: 20px;
    margin-top: 29px;
    margin-left: 40px;
    margin-right: 40px;
    cursor: pointer;
}

.deactivateText {
    color: var(----primary-text-color);
    font-family: Nunito;
    font-size: 20px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    margin-top: 50px;
}

.sign-in-button a {
    color: var(--default-background);
    text-align: center;
    font-feature-settings: 'case' on;
    font-family: Nunito;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 22px;
    letter-spacing: -0.408px;
    text-decoration: none;
}

.sign-in-button span {
    color: var(--default-background);
    text-align: center;
    font-feature-settings: 'case' on;
    font-family: Nunito;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 22px;
    letter-spacing: -0.408px;
    text-decoration: none;
}

.Otp_container h1 {
    color: var(----primary-text-color);
    font-family: Nunito;
    font-size: 30px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    margin-bottom: 12px;
}

.Otp_container {
    width: 453px;
    height: 70px;
    margin-top: 54px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-bottom: 25px;

}

.InputStyling {
    height: 35px;
    width: 2em !important;
    margin: 10px;
    border: 2px solid var(--Line-color) !important;
    border-radius: 7px;

}

.resend-otp-container {
    margin-top: 10px;
    text-align: center;
    display: flex;
    flex-direction: column;
}

.resend-otp-button {
    padding: 10px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
}

.resend-otp-button:disabled {
    background-color: #cccccc;
    color: #666666;
    cursor: not-allowed;
}

.ContactUs_header_Title h1 {
    font-family: Nunito;
    font-size: 15px;
    font-style: normal;
    font-weight: 700;
    color: var(--primary-button-color);
}

.crossIcon_alternate {
    position: absolute;
    left: 10px;
    top: 10px;
    background-color: var(--default-background);
    border: none;
    cursor: pointer;
    z-index: 99;
}

.text_input_container1 {
    width: 80%;
    flex-shrink: 0;
    border-radius: 15px;
    border: 1px solid var(--Line-color);
    background: var(--default-background);
    margin-top: 54px;
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    padding: 10px 10px 10px 10px;
}


.svgLogoImg {
    margin-top: 75px;
}

.headerTextContainer {
    margin-top: 10px;
}

.headerTextContainer h1 {
    color: var(----primary-text-color);
    font-family: Nunito;
    font-size: 30px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
}

.modal-content {
    background: #fff;
    border: 1px solid #ccc;
    border-radius: 4px;
    max-width: 80%;
    max-height: 80%;
    overflow: auto;
    position: relative;
}

.modal-content h3 {
    color: var(----primary-text-color);
    font-family: Nunito;
    font-size: 25px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    margin-bottom: 50px;
}

.modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1000;
}

.ContactUs_text_below_header {
    width: 395px;
    margin-bottom: 43px;
}

.ContactUs_text_below_header_deactivate {
    margin-top: 50px;
    width: 100%;
}

.ContactUs_text_below_header_deactivate p {
    font-family: Nunito;
    font-size: 25px;
    font-style: normal;
    font-weight: 700;
    color: var(--primary-text-color);
}

.deactivate {
    text-decoration: underline;
    color: var(--primary-button-color);
    cursor: pointer;
}

.ContactUs_text_below_header p {
    font-family: Nunito;
    font-size: 40px;
    font-style: normal;
    font-weight: 700;
    color: var(--primary-text-color);
}

.Input_fields_container {
    width: 100%;
    display: flex;
    flex-direction: row;
}

.left_text_inputs_container {
    width: 100%;
    display: flex;
    flex-direction: column;
}

.right_text_input_container {
    width: 100%;
    margin-left: 20px;
}

.email_container,
.name_container,
.phone_container,
.multifield_container {
    max-width: 529px;
    padding: 26px 29px 25px 29px;
    border: 1px solid #D4D3E0;
    border-radius: 15px;
    background-color: var(--default-background);
    margin-top: 19px;
    flex: 1;
}

.email_container input,
.name_container input,
.phone_container input,
.multifield_container textarea {
    width: 100%;
    border: none;
    color: var(--primary-text-color);
    font-family: Nunito;
    font-size: 18px;
    font-style: normal;
    font-weight: 500;
    line-height: 130%;
}

.email_container input:focus,
.name_container input:focus,
.phone_container input:focus,
.multifield_container textarea:focus {
    outline: none;
}

.Common_btn_container {
    margin-top: 50px;
}

.custom-website-modal-button {
    display: flex;
    align-items: center;
    justify-content: center;
}

.error {
    color: red;
    font-family: Nunito;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
}

@media only screen and (min-width: 768px) and (max-width: 1024px) {

    .ContactUs_inner_container {
        margin-bottom: 20px;
        padding: 25px;
    }

    .ContactUs_header_Title h1 {
        text-align: center;
    }

    .ContactUs_text_below_header {
        width: 96%;
    }

    .ContactUs_text_below_header p {
        text-align: center;
        font-size: 28px;
        line-height: 130%;
    }



    .Input_fields_container {
        width: 100%;
        display: flex;
        flex-direction: column;
    }


    .email_container,
    .name_container,
    .phone_container,
    .multifield_container {
        margin-left: 71px;
    }

    .right_text_input_container {
        margin-left: 0;
        height: auto;
    }


    .multifield_container .textarea {
        max-width: 100%;
    }

    .Common_btn_container {
        display: flex;
        align-items: center;
        justify-content: center;
        margin-top: 20px !important;
    }
}

@media (max-width: 768px) {
    .ContactUs_inner_container {
        margin-bottom: 20px;
        padding: 25px;
    }

    .ContactUs_header_Title h1 {
        text-align: center;
    }

    .ContactUs_text_below_header {
        width: 96%;
    }

    .ContactUs_text_below_header p {
        text-align: center;
        font-size: 28px;
        line-height: 130%;
    }

    .Input_fields_container {
        width: 100%;
        display: flex;
        flex-direction: column;
    }

    .right_text_input_container {
        margin-left: 0;
        height: auto;
    }


    .multifield_container .textarea {
        max-width: 100%;
    }

    .Common_btn_container {
        display: flex;
        align-items: center;
        justify-content: center;
        margin-top: 20px !important;
    }

    .text_input_container1 {
        width: 100%;
        justify-content: flex-start;
    }

    .svgLogoImg{
        margin-top: 25px !important;
    }

    .msgIcon {
        margin-left: 4px;

    }

    .verticle_line {
        margin-left: 10px;
    }

    .phoneNo_container {
        margin-left: 15px;
    }

    .phoneNo_container input {
        font-size: 15px !important;
        text-align: left;
    }

    .phoneNo_container input::placeholder {
        font-size: 12px;
    }

    .sign-in-button1 {
        width: 200px;
    }

    .Otp_container h1 {
        font-size: 17px !important
    }

    .InputStyling {
        margin: 5px !important;
        padding: 0 !important;
    }

    .headerTextContainer {
        margin-top: 15px !important;
    }

    .headerTextContainer h1 {
        color: var(----primary-text-color);
        font-family: Nunito;
        font-size: 30px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
    }

    .Otp_container{
        margin-top: 20px !important;
    }

}