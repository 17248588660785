div.hope-buddy-terms-and-conditions-main-container {
    margin-top: 24px;
}

div.title-container {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}

p {
    font-size: 16px;
    font-weight: 400;
}

p.title {
    font-weight: 700;
    text-decoration: underline;
}

p.para {
    margin-top: 20px;
    text-align: justify;
}

p.para-1 {
    margin-top: 12px;
}

span.bold {
    font-weight: 700;
}

p ul {
    margin-left: 40px;
    list-style-type: disc;
}

p ul li {
    margin-top: 8px;
}

p ol {
    margin-left: 40px;
    list-style-type: decimal;
}

p ol li.heading {
    font-weight: 700;
    text-decoration: underline;
}

p.para-1 ol {
    list-style-type: lower-roman;
}

p.para-1 ol.abcd {
    list-style-type: lower-alpha;
}

p.para-1 ol li {
    margin-top: 8px;
}

li.heading {
    margin-top: 16px;
}

p.para-1 ul.dash {
    list-style-type: none;
}

p.para-1 ul.none {
    list-style-type: none;
}

p.para-1 ul.dash li::before {
    content: "- ";
    margin-right: 8px;
}

p.para-1 a {
    text-decoration: underline;
}

@media screen and (max-width: 480px) {
    p ul, p ol {
        margin-left: 24px;
    }

    p ol li.heading {
        text-decoration: none;
        text-align: left;
    }
}