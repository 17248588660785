@import '../../Utils/Styles/Colors.css';
@import '../../Utils/Styles/Fonts.css';

.nutrition_aadharNumber_conatiner {
    margin-left: 0px;
}
.Submit_button{
    margin-top: 15px;
    padding: 16px 100px;

}
.innerText{
    margin-top: 25px;
    padding: 10px;
}
.content{
    margin-top: 10px;
    margin-bottom: 10px;
}

.View_Attachment {
    background-color: var(--default-background);
    border: none;
    color: var(--primary-button-color);
    font-family: Nunito;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    margin-top: 6px;
    cursor: pointer;
}

.certificate-table {
    width: 50%;
}

.View_file {
    /* background-color: #007bff; */
    /* background-color: var(--default-background);
    color: var(--primary-button-color); */
    background-color: var(--primary-button-color);
    color: var(--default-background);
    /* color: white; */
    padding: 8px 12px;
    border: none;
    cursor: pointer;
    text-align: center;
    margin: auto;
    display: block;
}

.doctor-professional-details-container {
    width: 100%;
    padding: 10px;
}

.doctor-professional-details-body {
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.certificate-item {
    display: flex;
    align-items: center;
    justify-content: flex-start;
}

.certificate-name {
    flex: 1;
}

.certificate-name-text {
    text-decoration: underline;
    cursor: pointer;
    font-size: 20px;
    color: var(--primary-text-color); /* Blue color for the clickable name */
}

.certificate-name-text:hover {
    text-decoration: none;
    color: var(--primary-button-color); /* Darker blue on hover */
}

.tableToCenter {
    margin-left: 65px;
}
