@import '../../Utils/Styles/Colors.css';
@import '../../Utils/Styles/Fonts.css';


table {
    width: 100%;
}

table thead tr th {
    color: var(--dashboard-border-color);
    font-family: Nunito;
    font-size: 13px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
}

table thead tr th:nth-child(7) {
    width: 15%;
}

tbody {
    border-bottom: 1px solid lightgray;
}


table tbody tr td {
    padding: 15px 0;
    color: var(--dashboard-border-color);
    font-family: Nunito;
    font-size: 13px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    text-align: center;
    width: 94px;
    height: 30px;
    border-bottom: 1px solid lightgray;

}

.custom-button {
    min-width: 94px;
    height: 30px;
    flex-shrink: 0;
    border-radius: 100px;
    border: none;
    color: black;
    font-family: Nunito;
    font-size: 13px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
}


th {
    border-bottom: 1px solid lightgray;
    padding: 15px 4px;
}

tfoot {
    color: gray;
}

tfoot th {
    font-weight: normal;
}

.status-icon {
    display: flex;
    align-items: center;
    justify-content: center;
}

.status-name {
    margin-left: 15px;
}



.status-icon .exclamationMark {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-left: 8px;
}

.view-status {
    cursor: pointer;
}


.eyeIcon {
    background-color: var(--default-background);
    border: none;
    cursor: pointer;

}