@import '../../Utils/Styles/Colors.css';
@import '../../Utils/Styles/Fonts.css';

.Header_container {
    display: grid;
    grid-template-columns: repeat(7, 10%);
    grid-gap: 32px;
    align-items: center;
    padding: 20px 0;
    position: sticky;
    top: 0;
    background: var(--default-background);
    z-index: 99;
}

.header-logo {
    height: 70px;
    width: auto;
}

.mobile_img_conatianer {
    display: none;
}

.Header_container .Logo {
    grid-column: span 4;
}

.Header_container .Logo img {
    width: 94.695px;
    height: 60.002px;
}

.Navbar_List {
    list-style: none;
    grid-column: 6 / span 4;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.Navbar_List li {
    outline: none;
}

.Navbar_List a {
    text-decoration: none;
    color: var(--primary-text-color);
    font-family: Nunito;
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
}

.Navbar_List a:hover {
    cursor: pointer;
}

.Sidebar {
    display: none;
}

@media screen and (max-width: 480px) {
    .header-logo {
        margin-left: 12px;
        height: 57px;
    }
}


/* Media query for mobile devices */
@media (max-width: 768px) {


    .Header_container {
        gap: 20px;
    }

    .mobile_img_conatianer {
        display: block;
        grid-column: 1;
        margin-left: 30px;
    }


    .webview_img_container {
        display: none;
    }

    .Navbar_List {
        display: none;
    }

    .MobileOnly {
        grid-column: 6;
        margin-left: 45px;
    }

    .Hamburger {
        width: 24px;
        height: 24px;
    }

}

/* Hide the hamburger icon on larger screens */
@media (min-width: 769px) {
    .MobileOnly {
        display: none;
    }
}

@media only screen and (min-width: 768px) and (max-width: 1024px) {


    .Header_container {
        gap: 20px;
    }

    .mobile_img_conatianer {
        display: block;
        grid-column: 1;
        margin-left: 30px;
    }


    .webview_img_container {
        display: none;
    }

    .Navbar_List {
        display: none;
    }

    .MobileOnly {
        grid-column: 8;
        margin-left: 45px;
    }

    .Hamburger {
        width: 24px;
        height: 24px;
    }
}