.logo-container1 {
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    align-items: center;
    width: 50%;
    margin: auto;
    margin-top: 60px;
}

.logo-btn1-active {
    width: 100px;
    height: 100px;
    border: 2px solid #ff974f;
    border-radius: 15px;
}

.logo-btn1-inactive {
    width: 100px;
    height: 100px;
    border: 2px solid lightgray;
    border-radius: 15px;
}

.pdf-container {
    border: 2px solid #ff974f;
    border-right-width: 0px;
    border-left-width: 0px;
    border-bottom-width: 0px;
    margin-top: 5px;
    padding-right: 64px;
    padding-left: 64px;
    padding-bottom: 64px;
}

.logoo-container {
    position: relative;
}

.logoo-container::after {
    content: "";
    position: absolute;
    top: 84px;
    right: 42px;
    width: 3px;
    height: 20px;
    border-left: 10px solid transparent;
    border-right: 10px solid transparent;
    border-bottom: 10px solid #ff974f;
    z-index: 9;
}

.logoo-container1 {
    position: relative;
}

.cur-point {
    cursor: pointer;
}

.logoo-container1::after {
    content: "";
    position: absolute;
    top: 84px;
    right: 42px;
    width: 3px;
    height: 20px;
    border-left: 10px solid transparent;
    border-right: 10px solid transparent;
    border-bottom: 10px solid #ff974f;
    z-index: 9;
}

@media screen and (max-width: 480px) {
    .pdf-container {
        padding-right: 20px;
        padding-left: 20px;
    }

    .logo-container1 {
        width: 100%;
        margin-top: 32px;
    }
}