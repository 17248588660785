@import '../../Utils/Styles/Colors.css';
@import '../../Utils/Styles/Fonts.css';

.KeyFeatures_Screen_Container {
    max-width: 1170px;
    margin: 20px auto 0 auto;
}

.KeyFeatures_inner_container {
    padding: 5px;
}

.mobile_tablist {
    display: none;
}

.tabs_container .tablist {
    display: flex;
    list-style: none;
    justify-content: space-around;
}

.tab_btn_container {
    width: 244px;
    height: 89px;
    background: var(--tab-button-color);
    border-radius: 20px !important;
    border-color: none !important;
    color: var(--default-background) !important;
    display: flex;
    align-items: center;
    justify-content: center;
    font-family: Nunito;
    font-size: 22px;
    font-style: normal;
    font-weight: 700;
    line-height: 100%;
    position: relative;
    padding: 8px 16px;
    cursor: pointer;
}

.tab_btn_container::after {
    content: "";
    position: absolute;
    left: 41%;
    right: 50%;
    bottom: -15px;
    height: 6px;
    border-radius: 20px;
    width: 60px;
    background-color: var(--tab-button-active-color);
    transform: scaleX(0);
    transform-origin: top bottom;
    transition: transform 0.3s ease;
}

.tab_btn_container.react-tabs__tab--selected::after {
    transform: scaleX(1);
}

.react-tabs__tab--selected {
    width: 244px;
    height: 89px;
    border-radius: 20px !important;
    background: var(--tab-button-active-color) !important;
    border-color: var(--tab-button-active-color) !important;
    color: var(--primary-text-color) !important;
    display: flex;
    align-items: center;
    justify-content: center;
    font-family: Nunito;
    font-size: 22px;
    font-style: normal;
    font-weight: 700;
    line-height: 100%;
    border: none !important;
    transition: all 0.5ms ease-in-out;
    outline: none;
}

.tabs_container .TabPanel {
    background-color: #E3E4F0;
    height: 460px;
    border-radius: 46px;
    margin: 29px 5px 0 5px;
    display: grid;
    grid-template-columns: repeat(7, 10%);
    column-gap: 36px;
    padding: 31px 29px;
}

.tabs_container .TabPanel .left_container {
    margin-left: 55px;
    margin-top: 46px;
    grid-column: 1/ 5;
}

.tabs_container .TabPanel .left_container h2 {
    color: #623BD6;
    font-family: Nunito;
    font-size: 20px;
    font-style: normal;
    font-weight: 700;
    line-height: 130%;
}

.tabs_container .TabPanel .left_container h1 {
    color: var(--primary-text-color);
    font-family: Nunito;
    font-size: 40px;
    font-style: normal;
    font-weight: 700;
    line-height: 130%;
}


.tabs_container .TabPanel .left_container .list_of_features_container {
    display: grid;
    grid-template-columns: repeat(6, 15%);
    column-gap: 32px;
    margin-top: 34px;
}

.tabs_container .TabPanel .left_container .list_of_features_container .list_of_features_container_left {
    grid-column: 1/ 4;
    margin-left: 25px;
}

.tabs_container .TabPanel .left_container .list_of_features_container .list_of_features_container_right {
    grid-column: 4/ 7;
    margin-left: 25px;
    width: 100%;
}

.tabs_container .TabPanel .left_container .list_of_features_container .list_of_features_container_left ul li,
.tabs_container .TabPanel .left_container .list_of_features_container .list_of_features_container_right ul li {
    color: var(--primary-text-color);
    font-family: Nunito;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: 170%;
    margin-top: 5px;
}


.tabs_container .TabPanel .right_container {
    grid-column: 5/ 9;
    height: 450px;
}

.tabs_container .TabPanel .right_container img {
    margin: 0 auto;
    display: block;
    max-height: 100%;
    max-width: 100%;
}


@media only screen and (min-width: 768px) and (max-width: 1024px) {


    .tabs_container .tablist {
        overflow: auto;
        justify-content: start;
        height: 140px;
        padding: 0 49px;
    }

    .tablist::-webkit-scrollbar {
        width: 0.5em;
    }

    .tablist::-webkit-scrollbar-track {
        background-color: transparent;
    }

    .tablist::-webkit-scrollbar-thumb {
        background-color: transparent;
    }


    .tab_btn_container {
        margin: 0 10px;
        min-width: 200px;
    }

    .react-tabs__tab--selected {
        min-width: 200px;
        margin: 0 19px;
        border-color: var(--tab-button-active-color) !important;
    }

    .tab_btn_container::after {
        content: "";
        position: absolute;
        left: 36%;
        right: 50%;
        bottom: -15px;
        height: 6px;
        border-radius: 20px;
        width: 60px;
        background-color: var(--tab-button-active-color);
        transform: scaleX(0);
        transform-origin: top bottom;
        transition: transform 0.3s ease;
    }

    .tabs_container .TabPanel {
        height: auto;
        display: grid;
        grid-template-columns: repeat(5, 10%);
        padding: 15px;
        margin: 0 auto !important;
        width: 84% !important;
    }


    .tabs_container .TabPanel .left_container {
        grid-column: 3/ 7 !important;
    }

    .tabs_container .TabPanel .left_container {
        margin-left: 29px;
        margin-top: 26px;
    }

    .tabs_container .TabPanel .left_container h1 {
        margin-top: 10px;
    }

    .tabs_container .TabPanel .left_container .list_of_features_container {
        overflow: hidden;
    }

    .tabs_container .TabPanel .left_container .list_of_features_container .list_of_features_container_left {
        grid-column: 1/ 6;
        margin-left: 15px;
    }

    .tabs_container .TabPanel .left_container .list_of_features_container .list_of_features_container_right {
        grid-column: 1/ 6;
        margin-left: 15px;
    }

    .TabPanel .right_container {
        grid-column: 1/7 !important;
        height: 300px !important;
        margin: 25px 15px 15px 15px;
    }

}




@media (max-width: 768px) {


    .tabs_container .tablist {
        overflow: auto;
        justify-content: start;
        height: 140px;
        padding: 0 49px;
    }

    .tablist::-webkit-scrollbar {
        width: 0.5em;
    }

    .tablist::-webkit-scrollbar-track {
        background-color: transparent;
    }

    .tablist::-webkit-scrollbar-thumb {
        background-color: transparent;
    }


    .tab_btn_container {
        margin: 0 10px;
        min-width: 200px;
    }

    .react-tabs__tab--selected {
        min-width: 200px;
        margin: 0 19px;
        border-color: var(--tab-button-active-color) !important;
    }

    .tab_btn_container::after {
        content: "";
        position: absolute;
        left: 36%;
        right: 50%;
        bottom: -15px;
        height: 6px;
        border-radius: 20px;
        width: 60px;
        background-color: var(--tab-button-active-color);
        transform: scaleX(0);
        transform-origin: top bottom;
        transition: transform 0.3s ease;
    }

    .tabs_container .TabPanel {
        height: auto;
        margin: 29px 10px 0 10px;
        display: grid;
        grid-template-columns: repeat(5, 10%);
        padding: 15px;
    }


    .tabs_container .TabPanel .left_container {
        grid-column: 1/ 6;
    }

    .tabs_container .TabPanel .left_container {
        margin-left: 29px;
        margin-top: 26px;
    }

    .tabs_container .TabPanel .left_container h1 {
        margin-top: 10px;
    }

    .tabs_container .TabPanel .left_container .list_of_features_container {
        overflow: hidden;
    }

    .tabs_container .TabPanel .left_container .list_of_features_container .list_of_features_container_left {
        grid-column: 1/ 6;
        margin-left: 15px;
    }

    .tabs_container .TabPanel .left_container .list_of_features_container .list_of_features_container_right {
        grid-column: 1/ 6;
        margin-left: 15px;
    }

    .TabPanel .right_container {
        grid-column: 1/7 !important;
        height: 300px !important;
        margin: 25px 15px 15px 15px;
    }

}