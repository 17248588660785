@import '../../Utils/Styles/Colors.css';
@import '../../Utils/Styles/Fonts.css';


body,
h1,
h2,
h3,
p,
ul,
ol,
li {
    margin: 0;
    padding: 0;
}

.container {
    margin: 0 auto;
    max-width: 1300px;
}

.Container_below_navbar {
    max-width: 1261px;
    margin: 0 auto;
    display: grid;
    grid-template-columns: repeat(8, 10%);
    column-gap: 36px;
    row-gap: 66px;
}

.screen_container {
    grid-column: 1/ 9;
}

.footer_main_screen_container {
    background: var(--primary-text-color);
}

.scroll-up-button {
    position: fixed;
    bottom: 20px;
    right: 20px;
    padding: 10px 20px;
    cursor: pointer;
    border: none;
    background-color: var(--primary-button-color);
    border-radius: 50%;
    height: 40px;
    width: 25px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.svg-icon {
    fill: var(--default-background);
}

.scroll-up-button {
    z-index: 1;
}

.scroll-up-button.active {
    display: block;
}





@media (max-width: 768px) {
    .Landing_page {
        margin: 0px;
        padding: 0px;
    }

    .Container_below_navbar {
        grid-template-columns: repeat(4, 1fr);
        column-gap: 16px;
        row-gap: 20px;
    }


    .screen_container {
        grid-column: 1/ 5;
    }

}