@import '../../Utils/Styles/Colors.css';
@import '../../Utils/Styles/Fonts.css';

.App_interface_section_container {
    max-width: 1170px;
    margin: 0 auto;
}


.Inner_App_interface_section_container {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}


.interface_banner_container .interface_header_Title {
    margin: 20px 0;
}

.interface_header_Title h1 {
    font-family: Nunito;
    font-size: 40px;
    font-style: normal;
    font-weight: 700;
    color: var(--primary-text-color);
    text-align: center;
}

.interface_text_below_header {
    width: 395px;
}

.interface_text_below_header p {
    text-align: center;
    font-family: Nunito;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    color: var(--primary-text-color);
    line-height: 170%;
}

.interface_banner_container {
    width: 917px;
    display: flex;
    /* align-items: center; */
    justify-content: center;
    margin-top: 52px;
}

.interface_banner_container .first_sc,
.interface_banner_container .second_sc,
.interface_banner_container .third_sc {
    margin: 0 10px;
    /* border-radius: 20px; */
    /* border: 1px solid #D6D9E0; */
    /* width: 100%; */
}

.interface_banner_container .first_sc {
    margin-top: 75px;
}

.interface_banner_container .third_sc {
    margin-top: 105px;
}

.interface_banner_container .first_sc img,
.interface_banner_container .second_sc img,
.interface_banner_container .third_sc img {
    max-width: 100%;
    margin: 0 auto;
    display: block;
    border-radius: 20px;
    border: 1px solid #D6D9E0;
}

.interface_banner_container .second_sc .quickActions {
    margin-bottom: 20px;
}

.interface_banner_container img {
    height: auto;
    width: 100%;
}

@media (max-width: 768px) {

    .App_interface_section_container {
        display: none;
    }
}