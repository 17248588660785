/* Import statements */
@import '../../Utils/Styles/Colors.css';
@import '../../Utils/Styles/Fonts.css';



.Banner_Container {
    max-width: 1260px;
    margin: 0px auto 20px auto;
}


.Banner_Container .react-multi-carousel-track {
    margin: 0 85px !important;
}

.inner_banner_container {
    display: grid;
    grid-template-columns: repeat(7, 10%);
    gap: 50px;
    row-gap: 0px;
}


.header_text_container {
    grid-column: 1/ 5;
}

.header_text {
    height: 195px;
    width: 551px;
    color: var(--primary-text-color);
    font-family: Nunito;
    font-size: 50px;
    font-style: normal;
    font-weight: 700;
}


.banner_img_container {
    margin: 0 0 35px 0;
    padding: 0;
    grid-column: 4/8;
}

.banner_img {
    border-radius: 22px;
    max-width: 100%;
    height: 378px;
    display: block;
    margin: 0 auto;
}

.mobile_img_banner_container {
    display: none;
}



.react-multi-carousel-dot button {
    border-color: var(--primary-button-color) !important;
}

.react-multi-carousel-dot--active button {
    background: var(--primary-button-color) !important;
}




/* Styles for Mobile View */
@media (max-width: 768px) {


    .Banner_Container {
        margin: 0px auto 20px auto;
    }


    .Banner_Container .react-multi-carousel-track {
        margin: 0px !important;
        height: 540px;
    }


    .react-multi-carousel-item {
        margin-right: 0 !important;
    }


    .inner_banner_container {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: space-evenly;
        gap: 0;
        margin: 25px 25px;
    }



    .header_text_container {
        width: 290px;
        margin-left: 5px;
        margin-bottom: 15px;
        display: flex;
        height: 180px;
    }

    .header_text {
        font-size: 32px;
        line-height: 130%;
    }

    .Banner_Common_Btn {
        margin-left: 5px;
    }


    .banner_img_container {
        display: none;
    }

    .mobile_img_banner_container {
        display: block;
        grid-column: 1;
    }

    .Mobile_Banner_Image {
        width: 100%;
        height: 263px;
    }


}


