@import '../../Utils/Styles/Colors.css';
@import '../../Utils/Styles/Fonts.css';



.Nurse-content-header h1 {
    color: var(--primary-text-color);
    font-family: Nunito;
    font-size: 25px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}

.Nurse_Accounts_details {
    margin-top: 29px;
    display: flex;
    justify-content: space-between;
    align-items: center;
}


.Nurse_Accounts_details h1 {
    color: var(--primary-text-color);
    font-family: Nunito;
    font-size: 15px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}

.Status_sort_container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 9px 20px;
}

.Status_sort_container p {
    color: #6B6974;
    font-family: Nunito;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}

.Status_sort_container h1 {
    color: #29282F;
    font-family: Nunito;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}

.Status_sort_container .downArrow {
    display: flex;
    justify-content: space-between;
    align-items: center;
    cursor: pointer;
}


.dropbtn {
    padding: 10px;
    border: none;
    cursor: pointer;
    color: #29282F;
    font-family: Nunito;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    background-color: var(--default-background);
}

.dropdown-content {
    position: absolute;
    top: 265px;
    right: 72px;
    display: flex;
    flex-direction: column;
    width: 157px;
    background-color: var(--default-background);
    box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
    z-index: 1;
}

.dropdown-content .dropdown-content-button {
    background-color: var(--default-background);
    outline: none;
    border: none;
    padding: 5px 0;
}

.dropdown-content .dropdown-content-button:hover {
    background-color: var(--dashboard-border-color);
    cursor: pointer;
}

.pagination {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 15px;
}

.comparision_sign {
    color: var(--primary-text-color) !important;
    width: 20px;
    height: 20px;

}

.pagination button {
    border: none;
    background-color: var(--default-background);
    margin: 16px 10px;
    color: var(--dashboard-border-color);
    font-family: Nunito;
    font-size: 15px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    cursor: pointer;
}

.pagination button.active {
    color: var(--primary-text-color);
    font-family: Nunito;
    font-size: 15px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
}

.leftArrow {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 18px;
    cursor: pointer;
}

.pagination .rightArrow {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-left: 10px;
    cursor: pointer;
}

.pagination p {
    color: var(--dashboard-border-color);
    font-family: Nunito;
    font-size: 15px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}

/* -----------------------view details sections starts here -------------------------------- */

.View_Details_Container {
    border: 2px solid #CFD1D6;
    border-radius: 15px;
    /* height: 100vh; */
    position: relative;
}

.crossIcon {
    position: absolute;
    right: 14px;
    top: 14px;
    background-color: var(--default-background);
    border: none;
    cursor: pointer;

}

.crossIcon_alternate {
    position: absolute;
    left: 10px;
    top: 10px;
    background-color: var(--default-background);
    border: none;
    cursor: pointer;
    z-index: 99;
}

.crossIcon_alternate_Activity {
    position: absolute;
    top: 10px;
    background-color: var(--default-background);
    border: none;
    cursor: pointer;
    z-index: 99;
    right: 20px;
    top: 20px;
}

/* personal Details section */

.personal_details_container {
    margin-top: 30px;
    margin-left: 28px;
    display: flex;
    align-items: center;

}

.profile_img_container {
    width: 77.194px;
    height: 79px;
    border-radius: 45.5px;
    background: #F4F6FA;
    display: flex;
    align-items: center;
    justify-content: center;
}

.cameraIcon {
    background-color: var(--default-background);
    border: none;
    cursor: default;
}

.personal_details_container .Personal_Details_text {
    color: var(--primary-text-color);
    font-family: Nunito;
    font-size: 22px;
    font-style: normal;
    font-weight: 700;
    line-height: 28px;
    margin-left: 15px;
}

.Name_Mobile_No_container,
.third_input_section_container {
    display: flex;
    align-items: center;
    margin-left: 28px;
    margin-top: 20px;
}

.Label_text {
    color: var(--primary-text-color);
    font-family: Nunito;
    font-size: 20px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
}

.red-star::after {
    content: '*';
    color: #FF3B30;
    font-family: Nunito;
    font-size: 20px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
}

.name_text_input_container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 18px;
}

.first_name_container,
.last_name_container {
    display: flex;
    align-items: center;
    width: 325px;
    padding: 7px 24px;
    gap: 10px;
    border-radius: 15px;
    background: #F4F6FA;
}

.full_width {
    width: 1033px;
}

.first_name_container input,
.last_name_container input,
.mobile_no_Textinput_container input,
.pincode_Textinput_container input,
.Dob_Textinput_container input {
    width: 247px;
    color: #A9B2C2;
    background: #F4F6FA;
    font-family: Nunito;
    font-size: 17px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}

.mobile_no_container {
    margin-left: 28px;
}

.pincode_no_container {
    margin-left: 30px;

}



.mobile_no_Textinput_container {
    display: flex;
    align-items: center;
    width: 262px;
    padding: 7px 24px;
    gap: 10px;
    border-radius: 15px;
    background: #F4F6FA;
    margin-top: 18px;
}

.pincode_Textinput_container {
    display: flex;
    align-items: center;
    width: 262px;
    padding: 7px 24px;
    gap: 10px;
    border-radius: 15px;
    background: #F4F6FA;
    margin-top: 18px;
}

.Dob_Textinput_container {
    display: flex;
    align-items: center;
    width: 318px;
    padding: 7px 24px;
    gap: 10px;
    border-radius: 15px;
    background: #F4F6FA;
    margin-top: 18px;
}

.Rn_certificate_text_input_container .first_name_container {
    display: flex;
    width: 325px;
    padding: 7px 24px;
    align-items: center;
    gap: 10px;
    border-radius: 15px;
    background: #F4F6FA;
    margin-top: 18px;
}

.aadharNumber_conatiner,
.Employee_ID_conatiner,
.Organization_conatiner {
    margin-left: 29px;
}

.Employee_ID_conatiner .Rn_certificate_text_input_container .first_name_container {
    display: flex;
    width: 250px;
    padding: 7px 24px;
    align-items: center;
    gap: 10px;
    border-radius: 15px;
    background: #F4F6FA;
}

.Employee_ID_conatiner .Rn_certificate_text_input_container .first_name_container input {
    width: 210px;
}

.View_Attachment {
    background-color: var(--default-background);
    border: none;
    color: var(--primary-button-color);
    font-family: Nunito;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    margin-top: 6px;
    cursor: pointer;
}

.View_Attachment_dummy {
    background-color: var(--default-background);
    border: none;
    color: var(--default-background);
    font-family: Nunito;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    margin-top: 6px;
}

/* professional Details section */

.professional-details-header {
    margin: 40px 0 19px 30px;
}

.doctor-professional-details-table-container {
    margin: 25px 35px 0 40px;
    width: 93% !important;
}

.section_Division {
    width: 1095px;
    border: 1px solid;
    opacity: 0.1;
    margin: 40px 0 0px 30px
}

.professional-details-header h1 {
    color: #1C1B1F;
    font-family: Nunito;
    font-size: 22px;
    font-style: normal;
    font-weight: 700;
    line-height: 28px;
}

.approve_container {
    margin: 23px 0 48px 38px;
}

.bottom_spacing {
    margin-bottom: 30px;
}

.below_approve_conatiner {
    display: flex;
    align-items: center;
    margin-top: 20px;
}

.approve-dropdown .select {
    border: none;
    display: flex;
    align-items: center;
    padding: 17px 24px;
    border-radius: 15px;
    background: var(--box-background);
    outline: none;
    color: var(--primary-text-color);
    font-family: Nunito;
    font-size: 17px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}

.approve-dropdown select option {
    outline: none !important;
    border: none !important;
}

.Comments_Textinput_container {
    display: flex;
    align-items: center;
    width: 325px;
    padding: 7px 24px;
    gap: 10px;
    border-radius: 15px;
    background: var(--box-background);
    margin-left: 23px;
}

.Comments_Textinput_container input {
    background: var(--box-background);
    color: #A9B2C2;
    font-family: Nunito;
    font-size: 17px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}


.Submit_button {
    display: flex;
    padding: 16px 122px;
    border-radius: 14px;
    background: var(--primary-button-color);
    cursor: pointer;
    justify-content: center;
    align-items: center;
    background: var(--primary-button-color);
    margin-left: 25px;
}

.Activity_button {
    display: block;
    margin-top: 35px;
    border-radius: 14px;
    width: max-content;
    cursor: pointer;
    justify-content: center;
    align-items: center;
}

.Activity_button a {
    color: var(--primary-button-color);
    text-align: center;
    font-feature-settings: 'case' on;
    font-family: Nunito;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 22px;
    letter-spacing: -0.408px;
    text-decoration: none;
}

.Submit_button a {
    color: var(--default-background);
    text-align: center;
    font-feature-settings: 'case' on;
    font-family: Nunito;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 22px;
    letter-spacing: -0.408px;
    text-decoration: none;
}

.dropDownConatiner {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 325px;
    padding: 17px 24px;
    align-items: flex-start;
    border-radius: 15px;
    background: var(--box-background);
    cursor: pointer;
    position: relative;
}

.DropDowndownArrow {
    display: flex;
    align-items: center;
}

.DropDownContent {
    position: absolute;
    top: 50px;
    left: 0;
    display: flex;
    flex-direction: column;
    width: 100%;
    background-color: var(--default-background);
    box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
    z-index: 1;
}

.Active_Drop_Down_content {
    border: none;
    outline: none;
    z-index: 22;

}

.Active_DropDown_Status_Data_Button {
    width: 100%;
    border: none;
    outline: none;
    background-color: var(--default-background);
    cursor: pointer;
    display: flex;
    align-items: center;
    flex-direction: column;
    margin: 14px 0;
}


.modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1000;
}

.modal-content {
    background: #fff;
    border: 1px solid #ccc;
    border-radius: 4px;
    padding: 20px;
    max-width: 80%;
    max-height: 80%;
    overflow: auto;
    position: relative;
}

.modal-image {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.modal-close-button {
    margin-top: 10px;
    cursor: pointer;
    padding: 8px 12px;
    background-color: #007bff;
    color: #fff;
    border: none;
    border-radius: 4px;
    outline: none;
}

.modal-close-button:hover {
    background-color: #0056b3;
}

.numberError p {
    color: red;
}

.statusContainer {
    display: flex;
    flex-direction: column;
    position: relative;
}

.errorStatus {
    position: absolute;
    bottom: -20px;
    left: 5px
}

.errorComment {
    position: absolute;
    bottom: -20px;
    left: 30px
}

.commentContainer {
    display: flex;
    flex-direction: column;
    position: relative
}

.doctorMobile_no_Textinput_container {
    display: flex;
    align-items: center;
    width: 262px;
    padding: 7px 24px;
    gap: 10px;
    border-radius: 15px;
    background: #F4F6FA;
}

.doctorMobile_no_Textinput_container input {
    background: #F4F6FA;
}