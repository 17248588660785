@import '../../Utils/Styles/Colors.css';
@import '../../Utils/Styles/Fonts.css';


.Sign-container {
    display: grid;
    grid-template-columns: 1fr;
    grid-gap: 20px;
}
.numberError{
    width:60%;
}
.signin-main-container {
    background: var(--default-background);
    padding: 20px;
    box-shadow: none !important;
}

.content-container {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.svgLogoImg {
    margin-top: 100px;
}

.headerTextContainer {
    margin-top: 35px;
}

.headerTextContainer h1 {
    color: var(----primary-text-color);
    font-family: Nunito;
    font-size: 30px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
}

.headerTextContainer p {
    color: var(----primary-text-color);
    font-family: Nunito;
    font-size: 15px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    text-align: center;
}

.text_input_container {
    width: 30%;
    flex-shrink: 0;
    border-radius: 15px;
    border: 1px solid var(--Line-color);
    background: var(--default-background);
    margin-top: 35px;
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    padding: 10px 0px 10px 0px;
}

.msgIcon {
    width: 24px;
    height: 24px;
    flex-shrink: 0;
}

.verticle_line {
    width: 1px;
    height: 47px;
    opacity: 0.6;
    background: var(--Line-color);
}

.phoneNo_container p {
    color: var(--primary-text-color);
    font-family: Nunito;
    font-size: 15px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
}

.phoneNo_container input {
    width: 100%;
    border: none;
    color: var(--primary-text-color);
    font-family: Nunito;
    font-size: 18px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
}

.phoneNo_container input:focus {
    outline: none;
}


.checkCircle {
    width: 24px;
    height: 24px;
    flex-shrink: 0;
    margin-left: 61px;
}

.sign-in-button {
    margin-left: 23px;
    display: flex;
    padding: 19px 100px 19px 100px;
    justify-content: flex-end;
    align-items: center;
    border-radius: 14px;
    background: var(--primary-button-color);
    margin-top: 35px;
    cursor: pointer;
}

.sign-in-button a {
    color: var(--default-background);
    text-align: center;
    font-feature-settings: 'case' on;
    font-family: Nunito;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 22px;
    letter-spacing: -0.408px;
    text-decoration: none;
}

.sign-in-button span {
    color: var(--default-background);
    text-align: center;
    font-feature-settings: 'case' on;
    font-family: Nunito;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 22px;
    letter-spacing: -0.408px;
    text-decoration: none;
}

.Otp_container h1 {
    color: var(----primary-text-color);
    font-family: Nunito;
    font-size: 30px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    margin-bottom: 12px;
}

.Otp_container {
    width: 453px;
    height: 70px;
    margin-top: 54px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-bottom: 25px;

}

.InputStyling {
    height: 35px;
    width: 2em !important;
    margin: 10px;
    border: 2px solid var(--Line-color) !important;
    border-radius: 7px;

}

.resend-otp-container {
    margin-top: 10px;
    text-align: center;
    display: flex;
    flex-direction: column;
}

.resend-otp-button {
    padding: 10px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
}

.resend-otp-button:disabled {
    background-color: #cccccc;
    color: #666666;
    cursor: not-allowed;
}