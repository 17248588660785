@import '../../Utils/Styles/Colors.css';
@import '../../Utils/Styles/Fonts.css';



.modal {
    display: none;
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.70);
    z-index: 999;
    overflow-x: hidden;

}

.modal2 {
    display: none;
    position: fixed;
    top: 0;
    right: 0;
    width: 100%;
    height: 100vh;
    background: rgba(0, 0, 0, 0.70);
    z-index: 999;
    overflow-x: hidden;
}

.modal2.open {
    display: block;
}

.modal.open {
    display: block;
}

.modal.open .modal-content {
    position: absolute;
    top: 0;
    right: 0;
    width: 60%;
    background-color: var(--default-background);
    animation: slide-in 0.3s ease-in-out;
}

.modal2.open .modal-content2 {
    position: absolute;
    top: 0;
    right: 0;
    width: 60%;
    height: 100%;
    background-color: var(--default-background);
    animation: slide-in 0.3s ease-in-out;
}

.modal.closed .modal-content {
    animation: slide-out 0.3s ease-in-out;
}

.modal2.closed .modal-content2 {
    animation: slide-out 0.3s ease-in-out;
}


@keyframes slide-in {
    from {
        transform: translateX(100%);
    }

    to {
        transform: translateX(0);
    }
}

@keyframes slide-out {
    from {
        transform: translateX(0);
    }

    to {
        transform: translateX(100%);
    }
}

.Logo_inside_hamburger {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    margin-bottom: 38px;
    margin-right: 23px;
    margin-top: 38px;
}

.modal-items {
    list-style: none;
    padding: 0;
    text-align: right;
}

.modal-items li {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    height: 53px;
    justify-content: center;
    margin-right: 27px;
    transition: background-color 1s ease;
}

.modal-items li:hover {
    background-color: var(--modal-color);
}

.modal-items a {
    text-decoration: none;
    font-size: 20px;
    color: var(--modal-color);
    font-family: Nunito;
    font-style: normal;
    font-weight: 700;
    transition: color 1s ease;
    margin-right: 27px;
}

.modal-items a:hover {
    color: var(--default-background);
}