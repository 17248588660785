:root {
    --primary-button-color: #8B63FF;
    --secondary-button-color: #FF974F;
    --primary-text-color: #2D3142;
    --primary-inactive: #9c9eb9;
    --text-input-border: #d6d9e0;
    --secondary-text-color: #ffffff;
    --border-color: #bdbdbd;
    --success-color: #4fab53;
    --placeholder-text-color: #A9B2C2;
    --modal-background: rgba(0, 0, 0, 0.5);
    --red: #ff3b30;
    --default-background: #FFFFFF;
    --box-background: #F4F6FA;
    --light-blue: #E4DFFF;
    --modal-color: #6C63FF;
    --tab-button-color: #AF8EFF;
    --tab-button-active-color: #FFCC01;
    --footer_text_color: #8E9BAA;
    --Line-color: #C3C1CA;
    --dashboard-border-color: #86848E;
    --profile-border : #E8E8E8
}