@import '../../Utils/Styles/Colors.css';
@import '../../Utils/Styles/Fonts.css';



.Section_Two_Container {
    max-width: 1180px;
    margin-top: 57px auto;
}

.Section_Two_Container .text_content_container {
    display: grid;
    grid-template-columns: repeat(8, 10%);
    column-gap: 36px;
}

.Section_Two_Container .text_content_container .Header_Title {
    margin: 0px 0 45px 0;
    grid-column: 3/7;
}

.Section_Two_Container .text_content_container .Header_Title h1 {
    margin: 0;
    font-family: Nunito;
    font-size: 40px;
    font-style: normal;
    font-weight: 700;
    text-align: center;
    color: var(--primary-text-color);
}

.Section_Two_Container .text_content_container .paragraph {
    grid-column: 2/8;
    margin-left: 75px;
}

.Section_Two_Container .text_content_container .paragraph p {
    color: var(--primary-text-color);
    font-family: Nunito;
    font-size: 24px;
    font-style: normal;
    font-weight: 600;
    margin: 0;
}

.Section_Two_Container .text_content_container .seperate_text {
    grid-column: 3/7;
    margin-left: 110px;
    margin-bottom: 25px;
}

.Section_Two_Container .text_content_container .seperate_text p {
    color: var(--primary-text-color);
    font-family: Nunito;
    font-size: 24px;
    font-style: normal;
    font-weight: 600;
    margin: 0;
    padding: 0;
}

.Section_Two_Container .text_content_container .seperate_text_second {
    grid-column: 3/7;
    margin-left: 221px;

}

.Section_Two_Container .text_content_container .seperate_text_second p {
    color: var(--primary-text-color);
    font-family: Nunito;
    font-size: 24px;
    font-style: normal;
    font-weight: 600;
    margin: 0;
    padding: 0;
}

.mobile_paragraph,
.Second_mobile_paragraph {
    display: none;
}

@media only screen and (min-width: 768px) and (max-width: 1024px) {

    .Section_Two_Container {
        margin-bottom: 51px;
    }

    .Section_Two_Container .text_content_container {
        grid-template-columns: repeat(5, 10%);
        column-gap: 36px;
        row-gap: 0;
    }

    .Section_Two_Container .text_content_container .Header_Title {
        margin: 0;
        grid-column: 1/7 !important;
        margin-left: 0px !important;
    }

    .Section_Two_Container .text_content_container .Header_Title h1 {
        font-size: 28px;
        line-height: 130%;
    }

    .Section_Two_Container .text_content_container .paragraph {
        display: none;
    }

    .seperate_text {
        display: none;
    }

    .seperate_text_second {
        display: none;
    }

    .mobile_paragraph {
        display: block !important;
        grid-column: 1/7 !important;
        margin: 25px 20px !important;
    }

    .mobile_paragraph p {
        color: var(--primary-text-color);
        font-family: Nunito;
        font-size: 21px !important;
        font-style: normal;
        font-weight: 600;
        line-height: 130%;
        margin: 0;
        text-align: center;
    }

    .mobile_paragraph .first_seperate_text {
        margin: 0;
        color: var(--primary-text-color);
        font-family: Nunito;
        font-size: 21px !important;
        font-style: normal;
        font-weight: 600;
        line-height: 130%;
        text-align: center;

    }

    .Second_mobile_paragraph {
        display: block;
        grid-column: 1/7;
        margin: 0px 20px;
    }

    .Second_mobile_paragraph p {
        color: var(--primary-text-color);
        font-family: Nunito;
        font-size: 21px !important;
        font-style: normal;
        font-weight: 600;
        line-height: 130%;
        margin: 0;
        text-align: center;

    }

    .second_seperate_text {
        color: var(--primary-text-color);
        font-family: Nunito;
        font-size: 16px;
        font-style: normal;
        font-weight: 600;
        line-height: 130%;
        text-align: center;
        margin-left: 25px !important;
    }

}



@media (max-width: 768px) {

    .Section_Two_Container {
        margin-bottom: 51px;
    }

    .Section_Two_Container .text_content_container {
        grid-template-columns: repeat(5, 10%);
        column-gap: 36px;
        row-gap: 0;
    }

    .Section_Two_Container .text_content_container .Header_Title {
        margin: 0;
        grid-column: 1/6;
        margin-left: 60px;
    }

    .Section_Two_Container .text_content_container .Header_Title h1 {
        font-size: 28px;
        line-height: 130%;
    }

    .Section_Two_Container .text_content_container .paragraph {
        display: none;
    }

    .seperate_text {
        display: none;
    }

    .seperate_text_second {
        display: none;
    }

    .mobile_paragraph {
        display: block;
        grid-column: 1/7;
        margin: 25px 20px;
    }

    .mobile_paragraph p {
        color: var(--primary-text-color);
        font-family: Nunito;
        font-size: 16px;
        font-style: normal;
        font-weight: 600;
        line-height: 130%;
        margin: 0;
        text-align: center;
    }

    .mobile_paragraph .first_seperate_text {
        margin: 0;
        color: var(--primary-text-color);
        font-family: Nunito;
        font-size: 16px;
        font-style: normal;
        font-weight: 600;
        line-height: 130%;
        text-align: center;

    }

    .Second_mobile_paragraph {
        display: block;
        grid-column: 1/7;
        margin: 0px 20px;
    }

    .Second_mobile_paragraph p {
        color: var(--primary-text-color);
        font-family: Nunito;
        font-size: 16px;
        font-style: normal;
        font-weight: 600;
        line-height: 130%;
        margin: 0;
        text-align: center;

    }

    .second_seperate_text {
        color: var(--primary-text-color);
        font-family: Nunito;
        font-size: 16px;
        font-style: normal;
        font-weight: 600;
        line-height: 130%;
        text-align: center;
        margin-left: 25px !important;
    }
}