@import '../../Utils/Styles/Colors.css';
@import '../../Utils/Styles/Fonts.css';

.Pricing_Screen_Container {
    max-width: 1260px;
    margin: 0 auto;
}


.Pricing_inner_container {
    display: flex;
    justify-content: center;
    flex-direction: column;
}


.Pricing_inner_container .Pricing_header_Title {
    margin: 20px 0 50px 0;
}

.Pricing_inner_container .Pricing_header_Title h1 {
    font-family: Nunito;
    font-size: 15px;
    font-style: normal;
    font-weight: 700;
    color: var(--primary-button-color);
    text-align: center;
}



.pricing_tabs_container .pricing_tablist {
    display: flex;
    list-style: none;
    justify-content: space-evenly;
    padding: 0 20px;
}


.pricing_tabs_container .mobile_pricing_tablist {
    display: none;
}

.pricing_tab_btn_container {
    width: 244px;
    height: 89px;
    background: var(--tab-button-color);
    border-radius: 20px !important;
    border-color: none !important;
    color: var(--default-background) !important;
    display: flex;
    align-items: center;
    justify-content: center;
    font-family: Nunito;
    font-size: 22px;
    font-style: normal;
    font-weight: 700;
    line-height: 100%;
    position: relative;
    padding: 8px 16px;
    cursor: pointer;
}

.pricing_tab_btn_container::after {
    content: "";
    position: absolute;
    left: 41%;
    right: 50%;
    bottom: -15px;
    height: 6px;
    border-radius: 20px;
    width: 60px;
    background-color: var(--tab-button-active-color);
    transform: scaleX(0);
    transform-origin: top bottom;
    transition: transform 0.3s ease;
}

.pricing_tab_btn_container.react-tabs__tab--selected::after {
    transform: scaleX(1);
}

.react-tabs__tab--selected {
    width: 244px;
    height: 89px;
    border-radius: 20px !important;
    background: var(--tab-button-active-color) !important;
    border-color: var(--tab-button-active-color) !important;
    color: var(--primary-text-color) !important;
    display: flex;
    align-items: center;
    justify-content: center;
    font-family: Nunito;
    font-size: 22px;
    font-style: normal;
    font-weight: 700;
    line-height: 100%;
    border: none !important;
    transition: all 0.5ms ease-in-out;
    outline: none;
}

.pricing_TabPanel_container h1 {
    color: var(--primary-text-color);
    text-align: center;
    font-family: Nunito;
    font-size: 40px;
    font-style: normal;
    font-weight: 700;
    line-height: 130%;
    margin: 43px 0 34px 0;
}


.pricing_list_container {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}


.pricing_card_container {
    position: relative;
    margin-right: 20px;
    margin-left: 20px;
    width: 275px;

}


._pricing_card_header_container {
    position: absolute;
    top: 0;
    left: 55px;
    border-radius: 40px;
    border: 1px solid #AF8EFF;
    background: #EFEFEF;
    width: 187px;
    height: 52px;
}

.pricing_card_header {
    margin: 13px 0;
}

.pricing_card_header h1 {
    color: #2D3142;
    text-align: center;
    font-family: Nunito;
    font-size: 20px;
    font-style: normal;
    font-weight: 700;
    line-height: 130%;
    margin: 0;
    padding: 0;
}


.pricing_card_content_container {
    margin-top: 25px;
    border-radius: 20px;
    border: 1.5px solid #AF8EFF;
    width: 246px;
    padding: 38px 26px 31px 28px;
    height: 550px;
}

.pricing_card_content_container .content_inner_header h1 {
    color: var(--primary-text-color);
    font-family: Nunito;
    font-size: 22px;
    font-style: normal;
    font-weight: 700;
    line-height: 130%;
}

.pricing_card_content_container .pricing_features_container .features_header_container {
    margin: 0 0 20px 0;
}



.pricing_card_content_container .pricing_features_container .features_header_container h1 {
    margin: 0;
    padding: 0;
    color: var(--primary-text-color);
    font-family: Nunito;
    font-size: 20px;
    font-style: normal;
    font-weight: 700;
    line-height: 130%;
    text-align: start;
}


.pricing_card_content_container .pricing_features_container .list_below_features {
    height: 230px;
}

.pricing_card_content_container .pricing_features_container .custom_list_below_features {
    height: 155px;
}


.pricing_card_content_container .pricing_features_container ul li {
    color: var(--primary-text-color);
    font-family: Nunito;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 130%;
    margin-left: 23px;
}


.pricing_card_content_container .pricing_features_container .subscription_header_container {
    margin: 30px 0;
}

.pricing_card_content_container .pricing_features_container .subscription_header_container h1 {
    margin: 0;
    padding: 0;
    color: var(--primary-text-color);
    font-family: Nunito;
    font-size: 20px;
    font-style: normal;
    font-weight: 700;
    line-height: 130%;
    text-align: start;
}


@media only screen and (min-width: 768px) and (max-width: 1024px) {
    .pricing_tabs_container .pricing_tablist {
        overflow: auto;
        justify-content: start;
        height: 140px;
        padding: 0 49px;
    }

    .Pricing_inner_container .Pricing_header_Title {
        margin: 25px 0;
    }

    .pricing_tablist::-webkit-scrollbar {
        width: 0.5em;
    }

    .pricing_tablist::-webkit-scrollbar-track {
        background-color: transparent;
    }

    .pricing_tablist::-webkit-scrollbar-thumb {
        background-color: transparent;
    }

    .pricing_tablist .pricing_tab_btn_container {
        margin: 0 10px !important;
        min-width: 200px !important;
    }

    .pricing_TabPanel_container h1 {
        font-size: 19px;
        line-height: 130%;
    }

    .pricing_list_container {
        overflow: auto;
        justify-content: space-between;
    }

    .pricing_list_container::-webkit-scrollbar {
        width: 0.5em;
    }

    .pricing_list_container::-webkit-scrollbar-track {
        background-color: transparent;
    }

    .pricing_list_container::-webkit-scrollbar-thumb {
        background-color: transparent;
    }


    .pricing_card_container {
        margin: 0 23px;
    }

    .pricing_card_container:last-child {
        margin-right: 50px;
    }


}


@media (max-width: 768px) {

    .pricing_tabs_container .pricing_tablist {
        overflow: auto;
        justify-content: start;
        height: 140px;
        padding: 0 49px;
    }

    .Pricing_inner_container .Pricing_header_Title {
        margin: 25px 0;
    }

    .pricing_tablist::-webkit-scrollbar {
        width: 0.5em;
    }

    .pricing_tablist::-webkit-scrollbar-track {
        background-color: transparent;
    }

    .pricing_tablist::-webkit-scrollbar-thumb {
        background-color: transparent;
    }

    .pricing_tablist .pricing_tab_btn_container {
        margin: 0 10px !important;
        min-width: 200px !important;
    }

    .pricing_TabPanel_container h1 {
        font-size: 19px;
        line-height: 130%;
    }

    .pricing_list_container {
        overflow: auto;
        justify-content: space-between;
    }

    .pricing_list_container::-webkit-scrollbar {
        width: 0.5em;
    }

    .pricing_list_container::-webkit-scrollbar-track {
        background-color: transparent;
    }

    .pricing_list_container::-webkit-scrollbar-thumb {
        background-color: transparent;
    }

    .pricing_card_container {
        margin: 0 23px;
    }

    .pricing_card_container:last-child {
        margin-right: 50px;
    }

}