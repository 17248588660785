@import '../../Utils/Styles/Colors.css';
@import '../../Utils/Styles/Fonts.css';


.main-dashboard-contianer {
    background-color: var(--default-background);
}



.dashboard-container {
    display: flex;
    min-height: 100vh;
    position: relative;
    z-index: 1;
}


.border_below_profile {
    position: absolute;
    top: 105px;
    width: 100%;
    height: 1px;
    opacity: 0.6;
    background: var(--dashboard-border-color);
    z-index: 0;
}


.right-arrow-icon {
    position: absolute;
    top: 91px;
    left: 201px;
    width: 32px;
    height: 32px;
    flex-shrink: 0;
    border: 1px solid var(--Line-color);
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 99 !important;
    background-color: var(--default-background);
}

.right-arrow-icon .rightArrow {
    display: flex;
    align-items: center;
    justify-content: center;
}

.border-sidebar {
    position: absolute;
    left: 250px;
    width: 1.8px;
    height: 100%;
    opacity: 0.6;
    background: var(--dashboard-border-color);
    z-index: 0;
}




.sidebar {
    width: 250px;
    padding: 20px;
}

.header {
    width: 83%;
}

.top_header_container {
    height: 100px;
    display: flex;
    align-items: center;
    margin: 0 35px;

}

.search_container {
    display: flex;
    align-items: center;
    width: 1010px;
    padding: 5px 21px;
    gap: 12px;
    border-radius: 30px;
    border: 1px solid var(--dashboard-border-color);
    background: var(--default-background);
}

.input-container {
    width: 95%;
}

input[type="text"] {
    width: 100%;
    padding: 7px;
    border: none;
    color: var(--primary-text-color);
    font-family: Nunito;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    margin-bottom: 5px;
}

input[type="text"]::placeholder {
    color: var(--dashboard-border-color);
    font-family: Nunito;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;

}


input[type="text"]:focus {
    outline: none;
    color: var(--primary-text-color);
    font-family: Nunito;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
}

.seperater_container {
    width: 1px;
    height: 49px;
    opacity: 0.6;
    background: var(--dashboard-border-color);
    margin: 0 35px;
}

.bell_icon_container {
    width: 56px;
    height: 56px;
    flex-shrink: 0;
    border: 1px solid var(--dashboard-border-color);
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    position: relative;
    z-index: 1;
}

.bellIcon {
    display: flex;
    align-items: center;
    justify-content: center;
}

.pending_Task_Modal_Container {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: transparent;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
    transition: box-shadow 0.3s ease;
    z-index: 1;
}

.dashboard_active_arrow_element {
    content: "";
    position: absolute;
    top: 50px;
    right: 111px;
    width: 3px;
    height: 20px;
    border-left: 10px solid transparent;
    border-right: 10px solid transparent;
    border-bottom: 10px solid var(--default-background);
    z-index: 999;
}

.down_Arrow_Element::after {
    content: "";
    position: absolute;
    top: 50px;
    right: 113px;
    width: 3px;
    height: 20px;
    border-left: 10px solid transparent;
    border-right: 10px solid transparent;
    border-bottom: 10px solid var(--default-background);
    z-index: 999;
}

.pending_Task .down_Arrow_Element::after {
    content: "";
    position: absolute;
    top: 56px;
    right: 124px;
    transform: translateX(50%);
    width: 0px;
    height: 14px;
    border-left: 10px solid transparent;
    border-right: 10px solid transparent;
    border-bottom: 10px solid var(--default-background);
    z-index: 999;
}


.pending_Task_Modal_Content {
    position: absolute;
    top: 79px;
    right: 97px;
    height: 250px;
    width: 25%;
    border-radius: 12px;
    background-color: var(--default-background);
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
    transition: box-shadow 0.3s ease;
    z-index: 1;
}




.exitIcon {
    cursor: pointer;
}


.left_header_container {
    display: flex;
    align-items: center;
    justify-content: flex-start;
}

.img_container {
    margin-left: 20px;
    width: 56px;
    height: 58px;
    flex-shrink: 0;
    background-color: var(--profile-border);
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 458px;

}

.img_container img {
    width: 47px;
    height: 48px;
    flex-shrink: 0;
    border-radius: 458px;
}

.name_profession_container .profile_name {
    color: var(--primary-text-color);
    font-family: Nunito;
    font-size: 14px;
    font-style: normal;
    font-weight: bold;
    line-height: normal;
    margin-left: 20px;
}

.name_profession_container .profile_profession {
    color: var(--Line-color);
    font-family: Nunito;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}

.sidebar_navbar_container {
    width: 213px;
    height: 379px;
    flex-shrink: 0;
    margin-top: 65px;
}

.title_dashboard {
    display: flex;
    align-items: center;
    cursor: pointer;
    margin-top: 87px;
}

.title_dashboard .dashboard-Inactive {
    text-decoration: none;
    color: var(--primary-text-color);
    font-family: Nunito;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    margin-left: 20px;
}

.title_dashboard p:hover {
    color: var(--primary-text-color);
    font-family: Nunito;
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    outline: none;
}

.dashboard-active {
    color: var(--primary-text-color);
    font-family: Nunito;
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    text-decoration: none;
    line-height: normal;
    margin-left: 20px;

}



.sidebar_navbar_container ul li {
    list-style: none;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 31px 0;
    cursor: pointer;
}

.sidebar_navbar_container ul li .sidebar_Containts {
    display: flex;
    align-items: start;
    width: 80%;
}

.sidebar_navbar_container ul li .sidebar_Containts .item-Inactive {
    color: var(--Text-black, #29282F);
    font-family: Nunito;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    text-decoration: none;
    margin-left: 10px;
}

.sidebar_navbar_container ul li .sidebar_Containts .item-Inactive:hover {
    color: var(--Text-black, #29282F);
    font-family: Nunito;
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    margin-left: 10px;

}

.item-active {
    color: var(--Text-black, #29282F);
    font-family: Nunito;
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    margin-left: 10px;
}


.main-content {
    flex: 1;
    margin: 59px 48px;
}