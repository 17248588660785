@font-face {
    font-family: 'Nunito';
    src: url('../../Assets/Fonts/Nunito-Black.ttf') format('truetype'),
        url('../../Assets/Fonts/Nunito-BlackItalic.ttf') format('truetype'),
        url('../../Assets/Fonts/Nunito-Bold.ttf') format('truetype'),
        url('../../Assets/Fonts/Nunito-BoldItalic.ttf') format('truetype'),
        url('../../Assets/Fonts/Nunito-ExtraBold.ttf') format('truetype'),
        url('../../Assets/Fonts/Nunito-ExtraBoldItalic.ttf') format('truetype'),
        url('../../Assets/Fonts/Nunito-ExtraLight.ttf') format('truetype'),
        url('../../Assets/Fonts/Nunito-ExtraLightItalic.ttf') format('truetype'),
        url('../../Assets/Fonts/Nunito-Italic.ttf') format('truetype'),
        url('../../Assets/Fonts/Nunito-Light.ttf') format('truetype'),
        url('../../Assets/Fonts/Nunito-LightItalic.ttf') format('truetype'),
        url('../../Assets/Fonts/Nunito-Medium.ttf') format('truetype'),
        url('../../Assets/Fonts/Nunito-MediumItalic.ttf') format('truetype'),
        url('../../Assets/Fonts/Nunito-Regular.ttf') format('truetype'),
        url('../../Assets/Fonts/Nunito-SemiBold.ttf') format('truetype'),
        url('../../Assets/Fonts/Nunito-SemiBoldItalic.ttf') format('truetype');
    font-weight: 400;
    font-style: normal;
}

@font-face {
    font-family: 'Nunito';
    src: url('../../Assets//Fonts/Nunito-Black.ttf') format('truetype');
    font-weight: 900;
    font-style: normal;
}

@font-face {
    font-family: 'Nunito';
    src: url('../../Assets//Fonts/Nunito-BlackItalic.ttf') format('truetype');
    font-weight: 900;
    font-style: italic;
}

@font-face {
    font-family: 'Nunito';
    src: url('../../Assets/Fonts/Nunito-Bold.ttf') format('truetype');
    font-weight: 700;
    font-style: normal;
}

@font-face {
    font-family: 'Nunito';
    src: url('../../Assets/Fonts/Nunito-BoldItalic.ttf') format('truetype');
    font-weight: 700;
    font-style: italic;
}

@font-face {
    font-family: 'Nunito';
    src: url('../../Assets/Fonts/Nunito-ExtraBoldItalic.ttf') format('truetype');
    font-weight: 800;
    font-style: italic;
}

@font-face {
    font-family: 'Nunito';
    src: url('../../Assets/Fonts/Nunito-ExtraLight.ttf');
    font-weight: 200;
    font-style: normal;
}

@font-face {
    font-family: 'Nunito';
    src: url('../../Assets/Fonts/Nunito-ExtraLightItalic.ttf') format('truetype');
    font-weight: 200;
    font-style: italic;
}

@font-face {
    font-family: 'Nunito';
    src: url('../../Assets/Fonts/Nunito-Italic.ttf');
    font-weight: 400;
    font-style: italic;
}

@font-face {
    font-family: 'Nunito';
    src: url('../../Assets/Fonts/Nunito-Light.ttf') format('truetype');
    font-weight: 300;
    font-style: normal;
}

@font-face {
    font-family: 'Nunito';
    src: url('../../Assets/Fonts/Nunito-LightItalic.ttf') format('truetype');
    font-weight: 300;
    font-style: italic;
}

@font-face {
    font-family: 'Nunito';
    src: url('../../Assets/Fonts/Nunito-Medium.ttf');
    font-weight: 500;
    font-style: normal;
}

@font-face {
    font-family: 'Nunito';
    src: url('../../Assets/Fonts/Nunito-MediumItalic.ttf') format('truetype');
    font-weight: 500;
    font-style: italic;
}

@font-face {
    font-family: 'Nunito';
    src: url('../../Assets/Fonts/Nunito-Regular.ttf') format('truetype');
    font-weight: 400;
    font-style: normal;
}

@font-face {
    font-family: 'Nunito';
    src: url('../../Assets/Fonts/Nunito-SemiBold.ttf') format('truetype');
    font-weight: 600;
    font-style: normal;
}

@font-face {
    font-family: 'Nunito';
    src: url('../../Assets/Fonts/Nunito-SemiBoldItalic.ttf') format('truetype');
    font-weight: 600;
    font-style: italic;
}