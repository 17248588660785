@import '../../Utils/Styles/Colors.css';
@import '../../Utils/Styles/Fonts.css';


/* activity-log.css */
.activity-log {
  margin: 1rem;
}

.activity-table {
  width: 100%;
}

.left_spacing {
  margin-left: 400px;
}

.date .time .action {
  padding: 40px 0px;
}

.Activity_Log_Header {
  color: var(--primary-button-color);
  /* text-align: center; */
  font-feature-settings: 'case' on;
  font-family: Nunito;
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: 22px;
  letter-spacing: -0.408px;
  text-decoration: none;
  margin-left: 15px;
  margin-bottom: 10px;
}