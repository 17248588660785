/* Default styles */
body,
html {
    margin: 0;
    padding: 0;
    height: 100%;
}

.main-box {
    display: flex;
    justify-content: center;
    align-items: center;
}

.box-outline {
    padding: 20px;
    border: 2px solid #000;
    border-radius: 8px;
    width: 40%;
    margin-top: 25px;
}

.inside_content .info_message .info_message_text {
    margin: 0;
    padding: 0;
    color: #000;
    font-size: large;
}


.button_container {
    display: flex;
    justify-content: center;
    align-items: center;

}

.button_container .reject {
    margin-right: 50px;
}

.after_btn_click_txt {
    margin-top: 50px;
}

.button_container .reject .cross,
.after_btn_click_txt .cross {
    color: red;
}

.button_container .accept .tick,
.after_btn_click_txt .tick {
    color: #39FF14;
}

.commonButtonWithShadowHollow {
    font-size: 20px;
    border-radius: 10px;
    width: 100%;
    padding: 4px;
    font-weight: 600;
    transition: all 0.15s ease-in;
    background-color: var(--secondary-text-color);
    border-width: 0px;
    color: var(--primary-text-color);
    border: 2px #000 solid;
    margin-top: 8px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}

.commonButtonWithShadowHollow:hover {
    box-shadow: 2px 2px 5px 2px rgba(0, 0, 0, 0.5);
    cursor: pointer;
}

.error-message {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-bottom: 10px;
    color: red;
}

@media screen and (max-width: 768px) {
    .main-box {
        flex-direction: column;
    }

    .box-outline {
        width: 80%;
    }

    .button_container {
        flex-direction: column;
    }

    .button_container .reject {
        margin-right: 0;
        margin-bottom: 15px;
    }
}


@media screen and (max-width: 480px) {
    .box-outline {
        width: 70%;
    }
}